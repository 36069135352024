import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import {KatalMonitoringDriver, KatalMonitoringDriverOptions} from "@amzn/katal-monitoring-aws-driver";

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const getRealm = () => {
    const region = "us-east-1".toLowerCase();  // Filled from Webpack
    if (region === 'us-west-2' || region === 'ap-northeast-1') {
        // MFA/MILO integration is not setup in ap-northeast-1, so us-west-2 is used for JP
        return "JPAmazon";
    } else if(region === 'eu-west-1') {
        return  "EUAmazon";
    } else {
        return "USAmazon";
    }
}

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    if (process.env.NODE_ENV === 'test') {
        const metricsDriver = new KatalMetricsDriverArrayCollector();
        //  Attach to global window object so tests can see it
        (window as any).metricsDriver = metricsDriver;
        return metricsDriver;
    } else if (process.env.NODE_ENV !== 'production') {
        return new KatalMetricsDriverConsoleLogJson();
    } else {
        const sushiDriver = new KatalMetricsDriverSushi.Builder()
            .withDomainRealm("prod", getRealm())
            .withErrorHandler(metricsConsoleErrorHandler)
            .build();
        const monitoringConfig: KatalMonitoringDriverOptions = {
            url: "https://1y184vmgih.execute-api.us-east-1.amazonaws.com/prod/v1/monitoring",
            metricsSushiDriver: sushiDriver,
        }
        return new KatalMonitoringDriver(monitoringConfig);
    }

};

const makePublisher = (): KatalMetrics.Publisher => {

    const metricsDriver = makeMetricsDriver();

    // Application wide dimensions
    const dimensions = [
        // TODO: set rendering mode dimension
        // TODO: set warehouse location from cookie
        new KatalMetrics.Metric.String('stage', "prod"),
        new KatalMetrics.Metric.String('region', "us-east-1"),
    ]

    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite('FITManagementUI')
        .withServiceName('FITManagementUI')
        .withCloudWatchDimensions(dimensions)
        .build();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsConsoleErrorHandler,
        initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
